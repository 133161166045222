import { EnvelopeIcon, GlobeAltIcon, HomeIcon, ShareIcon, StarIcon } from "@heroicons/react/24/solid"

export const mySkills = <>
  <p className="text-lg font-extrabold md:text-xl font-serif text-white mb-5 md:mb-1">MY SKILLS</p>
  <hr className="hidden md:block lg:block"/>
  <p>Languages and Frameworks :</p>
  <p><a className="font-bold text-customGreen">Competent and hands-on experience: </a> React, Angular, Typescript, Javascript, NestJS, Node.js, Express, HTML, CSS, TailwindCSS</p>
  <p><a className="font-bold text-customGreen">Competent and hands-on experience: : </a>Object-oriented Languages, C# & ASP.NET, Java & Spring Boot,</p>
  <p><a className="font-bold text-customGreen">Databases : </a>Proficient in both relational database (MySQL) and non-relational database (MongoDB).</p>
  <p><a className="font-bold text-customGreen">Web Services :</a> AWS</p>
  <p><a className="font-bold text-customGreen">Operating Systems : </a>Familiar with Linux, Ubuntu.</p>
  <p><a className="font-bold text-customGreen">CI/CD Tools : </a>GitHub, GitLab</p>
  <p><a className="font-bold text-customGreen">Practical skills : </a>Web development, web interfaces, responsive web design, CI/CD.</p>
  <p><a className="font-bold text-customGreen">Agile & Scrum Master Skills : </a>Experienced in Agile methodologies with hands-on practice in Scrum.</p>
</>

const LeftSide = () => {
  const skill = (text: string, rate: number) => {
    const rows = []
    for (let i = 0; i < rate; i++) {
      rows[i] = i
    }
    const stroke = []
    for (let i = rate; i < 5; i++) {
      stroke[i] = i
    }
    return (
      <div className="md:flex md:items-center md:justify-between">
        <p className="ml-2 align-middle">{text}</p>
        <div className="flex flex-row">
          {rows.map((index) => <StarIcon key={index} className="h-[18px] w-[18px] text-blue-gray-500 align-middle" />)}
          {stroke.map((index) => <StarIcon key={index} className="h-[18px] w-[18px] text-blue-gray-500 align-middle" fill="none" stroke="currentColor" />)}
        </div>
      </div>
    )
  }

  return (
      <div>
        <div className="ml-3 gap-x-5 grid grid-rows-1 gap-3 overflow-hidden break-all">
          <p className="text-sm font-bold md:text-xl text-white font-serif">CONTACT</p>
          <hr/>
          <div className="flex items-center">
            <EnvelopeIcon className="h-[18px] w-[18px] text-blue-gray-500 align-middle"/>
            <p className="text-sm md:text-base ml-2 align-middle">developer@brucenguyen.org</p>
        </div>
        <div className="flex items-center">
          <HomeIcon className="h-[18px] w-[18px] text-blue-gray-500 align-middle"/>
          <p className="text-sm md:text-base ml-2 align-middle">Adelaide, Australia</p>
        </div>
        <div className="flex items-center">
          <GlobeAltIcon className="h-[18px] w-[18px] text-blue-gray-500 align-middle"/>
          <p className="ml-2 align-middle">0461449917</p>
        </div>
        <div className="flex items-center">
          <ShareIcon className="h-[18px] w-[18px] text-blue-gray-500 align-middle"/>
          <a
              href="https://www.linkedin.com/in/bruce-nguyen-4a3492272/"
              className="ml-2 align-middle"
              target="_blank"
              rel="noopener noreferrer"
          >Linkedin</a>
        </div>
      </div>

      <div className="mt-6 mx-3 gap-x-5 grid grid-rows-1 gap-3 overflow-hidden">
        {mySkills}
      </div>
    </div>
  )
}

export default LeftSide
